footer {
  position: relative;
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  height: 100%;
  padding: 0px 60px 24px;
  background-color: var(--main-bgcolor);
  display: flex;
  justify-content: space-between;

  .second_block__second_line_container_mobile {
    display: none;
  }

  .private_police {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8e8e8e;
  }

  .first_block {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    .logo {
      width: 131px;
      height: 22.2px;
      background: url("../../assets/images/logo.svg") no-repeat;
    }

    p {
      width: 100%;
      max-width: 400px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: var(--primary-color);
      opacity: 0.47;
      margin-right: 10px;
    }
  }

  .second_block {
    display: flex;
    flex-direction: column;
    gap: 55px;

    &__second_line_container {
      display: flex;
      gap: 30px;
    }

    .footer_nav {
      display: flex;
      align-items: center;
      gap: 10px;

      li {
        margin-right: 40px;

        a {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #222222;
        }
      }
    }
  }

  .third_block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;

    .footer_buttons {
      display: flex;
      align-items: center;
      height: 41px;

      .consult_btn {
        background-color: var(--accent-color);
        font-size: var(--secondary-font-size);
        line-height: 16.71px;
        color: var(--main-bgcolor);

        border: none;
        border-radius: 10px;
        padding: 11.5px 20px;

        cursor: pointer;
      }
    }

    .footer_socials {
      position: absolute;
      bottom: 24px;
      display: flex;
      align-items: center;
      gap: 16px;

      li {
        a {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #757095;
        }
      }
      .social_insta {
        width: 31px;
        height: 31px;
        background: url("../../assets/icons/insta.svg") no-repeat;
        cursor: pointer;
      }
      .social_in {
        width: 20px;
        height: 20px;
        background: url("../../assets/icons/linkedinBlack.svg") no-repeat;
        cursor: pointer;
      }
    }
  }

  .second_line {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer_socials {
      display: flex;
      align-items: center;

      li {
        margin-right: 15px;

        a {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #757095;
        }
      }

      .nav_item {
        margin-right: 40px !important;
      }
      .social_whatsapp {
        width: 31px;
        height: 31px;
        background: url("../../assets/icons/whatsappFooter.svg") no-repeat;
        cursor: pointer;
      }
      .social_insta {
        width: 31px;
        height: 31px;
        background: url("../../assets/icons/insta.svg") no-repeat;
        cursor: pointer;
      }
      .social_in {
        width: 31px;
        height: 20px;
        background: url("../../assets/icons/linkedinBlack.svg") no-repeat;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer_nav {
    li {
      margin-right: 15px !important;
    }
  }
}

@media (max-width: 1024px) {
  footer {
    padding: 60px 50px 0;

    .footer_socials {
      li {
        margin-right: 15px !important;
      }

      .nav_item {
        display: none;
      }
    }
  }
}

@media (max-width: 950px) {
  footer {
    padding: 20px 20px 0;

    .first_line {
      flex-direction: column !important;

      .logo {
        margin-bottom: 30px;
      }
    }

    .second_line {
      flex-direction: column;
      padding-bottom: 20px;

      ul {
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  footer {
    position: relative;
    padding-bottom: 24px;

    .second_block__second_line_container_mobile {
      display: none;
    }

    max-width: 100%;
    padding-inline: 40px;
  }
}

@media (max-width: 600px) {
  footer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    .first_block {
      margin-bottom: 16px;
      .second_block__second_line_container {
        display: none;
      }

      p {
        margin-right: 0;
      }
    }

    .third_block {
      align-items: baseline;
      margin-bottom: 40px;

      .footer_socials {
        position: absolute;
        right: 16px;
        top: 190px;
      }
    }

    .second_block__second_line_container_mobile {
      display: block;
      position: absolute;
      bottom: 24px;
      width: 190px;
      height: 20px;

      .private_police {
        margin-right: 30px;
        font-size: 12px;
        color: #171717;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  footer {
    .footer_buttons {
      height: 100%;

      li {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }

    .third_block {
      .footer_socials {
        top: 210px;
      }
    }
  }
}
