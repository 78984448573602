:root {
  //background
  --main-bgcolor: #ffffff;
  --secondary-bgcolor: #f8f8f8;

  //colors
  --accent-color: #8450a0;
  --title-color: #333333;
  --primary-color: #757095;
  --black: #000000;

  --gradient: linear-gradient(150deg, #8450a0 0%, rgba(111, 29, 244, 0.4) 100%);

  //font-sizes
  --title-font-size: 36px;
  --extra-title-font-size: 42px;
  --subtitle-font-size: 18px;
  --primary-font-size: 16px;
  --secondary-font-size: 14px;

  //width
  --desktop-width: 1440px;
}
