@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");

.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.form_wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 150px;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #262626;
    margin-bottom: 32px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form_item {
      width: 100%;
      margin-bottom: 20px;

      label {
        font-size: var(--secondary-font-size);
        line-height: 17px;
        color: #000000;
        margin: 0 6.5px 10px;
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        height: 56px;
        max-width: 418px;
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 11.5px 10px;
        outline: none;
      }

      input[type="text"],
      input[type="email"]::placeholder {
        font-size: var(--primary-font-size);
      }

      textarea {
        width: 100%;
        max-width: 418px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        padding: 10px;
        outline: none;
      }

      textarea::placeholder {
        font-family: Poppins, sans-serif;
        font-size: var(--primary-font-size);
        line-height: 21px;
      }

      input[type="checkbox"] {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }

      input[type="checkbox"]:checked:before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 6px;
        width: 5px;
        height: 11px;
        border: solid var(--accent-color);
        border-radius: 1px;
        border-width: 0 2px 2px 0;
        transform: rotate(35deg);
      }

      input[type="checkbox"]:checked {
        border-color: var(--accent-color);
      }
    }

    .checkbox_label {
      letter-spacing: -0.02em;
      line-height: 21px !important;
      margin: 0 6.5px 4px !important;
    }

    .submit_container {
      width: 100%;
    }

    // input[type="submit"] {
    //   width: 100%;
    //   max-width: 160px;
    //   background: var(--accent-color);
    //   font-size: var(--primary-font-size);
    //   font-weight: 600;
    //   line-height: 17px;
    //   color: #ffffff;
    //   border: none;
    //   border-radius: 50px;
    //   padding: 11.5px 0;
    //   margin: 0 auto;
    //   cursor: pointer;
    // }
  }

  .flex_col {
    display: flex;
    flex-direction: column;
  }

  .flex_ai_center {
    display: flex;
    align-items: center;
  }

  .flex_ai_start {
    display: flex;
    align-items: flex-start;
  }
}

.image_wrapper {
  width: 50%;

  .slide_img {
    width: 100%;
    height: auto;
  }
}

.label {
  font-size: var(--secondary-font-size);
  line-height: 17px;
  color: #000000;
  margin: 0 6.5px 10px;
  display: inline;

  & p {
    display: inline;
    color: var(--accent-color);
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-decoration: underline;
  }
}

@media (max-width: 1280px) {
  .form_wrapper {
    padding: 46px 60px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;

    .form_wrapper {
      width: 100%;
      padding: 46px 0;
      padding-inline: 40px;
      .title {
        width: 100%;
        font-size: 26px;
        font-weight: 600px;
        line-height: 36.4px;
      }
    }

    .image_wrapper {
      min-width: 100%;
      padding: 0px;
    }
  }
  input[type="submit"] {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-inline: 16px;

    .form_wrapper {
      width: 100%;
      padding: 40px 0;
      padding-inline: 0;

      .title {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 26px;
      }
    }

    .image_wrapper {
      min-width: 100%;
      padding: 0px;
    }
  }
}

@media (max-width: 480px) {
  .title {
    text-align: center;
  }
}
