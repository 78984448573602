.wrapper {
    width: 100%;
    max-width: var(--desktop-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 105px 0;
    margin-bottom: 60px;
    height: 82vh;
}

@media (max-width: 950px) {
    .wrapper {
        padding: 0 50px 0 !important;
    }
}
@media (max-width: 500px) {
    .wrapper {
        padding: 0 20px 0 !important;
    }
}