.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: var(--main-bgcolor);
}

.main_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 226px);
  overflow-x: hidden;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .main_content {
    height: calc(100% - 218px);
  }
}
