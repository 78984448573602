.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;
  margin-top: 0px;

  .image {
    width: 501px;
    height: 452px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 30px;
  }

  .step {
    width: 53px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--accent-color);
    font-size: 33px;
    font-weight: 600;
    line-height: 37.46px;
    color: var(--main-bgcolor);
    margin-bottom: 24px;
  }

  .title {
    font-size: var(--title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .description {
    width: 100%;
    max-width: 512px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 60px 50px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    margin-top: 0px;
    padding: 40px 20px;
    flex-direction: column-reverse !important;
    align-items: center;

    .wrapper_content {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }

    .title {
      font-size: 36px;
      line-height: 50.4px;
    }

    .description {
      text-indent: 20px;
      min-width: 100%;
      padding-inline: 20px;
      text-align: center;
      line-height: 22.4px;
    }

    .image {
      width: 100%;
      height: 402px;
      margin: 0 auto;
      background-position: center;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    margin-top: 0px;
    padding-top: 40px;
    padding-inline: 16px;

    flex-direction: column-reverse;

    .wrapper_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    .title {
      font-size: 26px;
      line-height: 36.4px;
      margin-bottom: 8px;
    }

    .description {
      text-align: center;
      line-height: 22.4px;
      font-size: var(--primary-font-size);
      color: var(--primary-color);
    }

    .image {
      width: 100%;
      height: 300px;
      margin: 0 auto;
      background-position: center;
      margin-bottom: 20px;
    }
  }
}
