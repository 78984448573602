.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 60px;
  padding-top: 0;

  .card {
    width: 100%;
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--main-bgcolor);
    background: var(--gradient);
    border-radius: 32px;
    padding: 60px 10px;
    margin: 0 auto;

    .title {
      width: 100%;
      max-width: 579px;
      font-size: 40px;
      line-height: 56px;
      font-weight: 600;
      letter-spacing: -0.03em;
      margin-bottom: 24px;
    }

    .change_width {
      max-width: 676px;
    }

    .description {
      width: 100%;
      max-width: 676px;
      font-size: var(--primary-font-size);
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-bottom: 24px;
    }

    .consult_btn {
      background-color: var(--main-bgcolor);
      font-size: var(--secondary-font-size);
      line-height: 16.71px;
      color: var(--accent-color);
      border: none;
      border-radius: 10px;
      padding: 11.5px 20px;
      margin-left: 6px;

      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 10px 50px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    padding: 40px 40px;
    .card {
      max-width: 100%;
      padding: 72px 10px;
      margin-bottom: 80px;

      .title {
        font-size: 36px !important;
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;
    margin-bottom: 40px;

    .card {
      padding: 48px 16px;

      .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }
  }
}
