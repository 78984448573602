@media (min-width: 601px) and (max-width: 1024px) {
  .card_wrapper {
    width: 100%;
    max-width: 412px;

    img {
      max-width: 212px;
      border-radius: 50%;
    }

    .card_descr {
      padding: 34px 29px;

      .name {
        font-size: var(--subtitle-font-size);
        line-height: 30px;
        font-weight: 600;
        letter-spacing: -0.02em;
      }

      .speciality {
        font-size: var(--primary-font-size);
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--primary-color);
        margin-bottom: 19px;
      }

      .country {
        display: flex;
        align-items: center;

        .UK_flag {
          width: 23px;
          height: 16px;
          background: url("../../assets/icons/UK_flag.svg") no-repeat;
          margin-right: 0;
        }

        .US_flag {
          width: 23px;
          height: 16px;
          background: url("../../assets/icons/US_flag.svg") no-repeat;
          margin-right: 0;
        }

        .EG_flag {
          width: 23px;
          height: 15.33px;
          background: url("../../assets/icons/EG_flag.svg") no-repeat;
          margin-right: 0;
        }
      }

      .languages {
        display: flex;
        align-items: center;
        margin-top: 7px;
      }

      .skills {
        display: flex;
        align-items: center;
        margin-top: 7px;

        .ar_skills {
          display: flex;
          flex-direction: column;
        }
      }

      .experience {
        display: flex;
        align-items: center;
        margin-top: 25px;

        span:first-of-type {
          font-size: 38px;
          line-height: 40px;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: var(--accent-color);
        }

        span:last-of-type {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: -0.02em;
          color: var(--primary-color);
        }
      }

      .square {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--accent-color);
        border-radius: 50%;
        margin-right: 9px;

        .lang {
          width: 17.58px;
          height: 17.09px;
          background: url("../../assets/icons/language.svg") no-repeat;
          margin-right: 0;
        }

        .certificate {
          width: 12.94px;
          height: 18.12px;
          background: url("../../assets/icons/certificate.svg") no-repeat;
          margin-right: 0;
        }
      }

      span {
        font-size: var(--primary-font-size);
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--primary-color);
        margin-right: 9px;
      }
    }
  }
  .arrow_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 56px;

    .arrow_content {
      width: 144px;
      height: 48px;
      display: flex;
      justify-content: space-between;
    }

    .arrow_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
    .arrow_icon {
      font-size: 42px;
      color: #081216;
      stroke: var(--main-bgcolor);
      stroke-width: 1;
    }
  }
}

@media (max-width: 600px) {
  .card_wrapper {
    width: 100%;

    img {
      width: 228px;
      height: 228px;
      margin: 0 auto;
      border-radius: 50%;
    }

    .card_descr {
      padding: 16px 0px;
      // min-width: 328px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      .name {
        font-size: var(--subtitle-font-size);
        line-height: 30px;
        font-weight: 600;
        letter-spacing: -0.02em;
      }

      .speciality {
        font-size: var(--primary-font-size);
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--primary-color);
        margin-bottom: 19px;
      }

      .country {
        display: flex;
        align-items: center;

        .UK_flag {
          width: 23px;
          height: 16px;
          background: url("../../assets/icons/UK_flag.svg") no-repeat;
          margin-right: 0;
        }

        .US_flag {
          width: 23px;
          height: 16px;
          background: url("../../assets/icons/US_flag.svg") no-repeat;
          margin-right: 0;
        }

        .EG_flag {
          width: 23px;
          height: 15.33px;
          background: url("../../assets/icons/EG_flag.svg") no-repeat;
          margin-right: 0;
        }
      }

      .languages {
        display: flex;
        align-items: center;
        margin-top: 7px;
      }

      .skills {
        display: flex;
        align-items: center;
        margin-top: 7px;

        .ar_skills {
          display: flex;
          flex-direction: column;
        }
      }

      .experience {
        display: flex;
        align-items: center;
        margin-top: 25px;

        span:first-of-type {
          font-size: 38px;
          line-height: 40px;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: var(--accent-color);
        }

        span:last-of-type {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: -0.02em;
          color: var(--primary-color);
        }
      }

      .square {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--accent-color);
        border-radius: 50%;
        margin-right: 9px;

        .lang {
          width: 17.58px;
          height: 17.09px;
          background: url("../../assets/icons/language.svg") no-repeat;
          margin-right: 0;
        }

        .certificate {
          width: 12.94px;
          height: 18.12px;
          background: url("../../assets/icons/certificate.svg") no-repeat;
          margin-right: 0;
        }
      }

      span {
        font-size: var(--primary-font-size);
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--primary-color);
        margin-right: 9px;
      }
    }
  }
  .arrow_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .arrow_content {
      width: 144px;
      height: 48px;
      display: flex;
      justify-content: space-between;
    }

    .arrow_block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
    .arrow_icon {
      font-size: 42px;
      color: #081216;
      stroke: var(--main-bgcolor);
      stroke-width: 1;
    }
  }
}
