.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px 100px;
  background-color: var(--secondary-bgcolor);
  position: relative;

  .companies_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    overflow-x: scroll;
    padding-bottom: 20px;

    .companies_wrapper_block_desktop {
      visibility: visible;
    }

    .companies_wrapper_block {
      display: none;
    }

    // .company {
    //     width: 150px;
    //     margin-right: 100px;
    // }

    // .company:last-of-type {
    //     margin-right: 0px;
    // }

    .company_1 {
      width: 165.81px;
      height: 62.67px;
    }

    .company_2 {
      width: 169px;
      height: 62px;
    }

    .company_3 {
      width: 72px;
      height: 72px;
      mix-blend-mode: darken;
    }

    .company_4 {
      width: 122px;
      height: 73px;
    }

    .company_5 {
      width: 250px;
      height: 72px;
    }
  }
}

@media (max-width: 1375px) {
  .companies_wrapper {
    display: flex;
    justify-content: flex-start !important;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    padding: 00px 20px;
    margin-bottom: 20px;
    width: 100%;
    .companies_wrapper {
      display: flex;
      overflow-x: scroll;
      flex-direction: row;
      flex-wrap: nowrap;
      // flex-direction: column;
      gap: 40px;
      width: 100%;

      .companies_wrapper_block_desktop {
        display: flex;
      }
      .companies_wrapper_block {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .wrapper_img_first {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: 24px;
        width: 486px;
      }
      .wrapper_img_second {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        width: 412px;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;
    margin-top: 50px;
    padding-top: 0;

    .companies_wrapper {
      display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      gap: 24px;
      padding-bottom: 20px;
    }
  }
}
