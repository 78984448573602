.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;

  .title {
    font-size: var(--title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .description {
    width: 100%;
    max-width: 762px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--primary-color);
  }

  .content_description {
    width: 100%;
    max-width: 762px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }

  .content_block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 82px;

    .step {
      width: 53px;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--accent-color);
      font-size: 33px;
      font-weight: 600;
      line-height: 37.46px;
      color: var(--main-bgcolor);
      margin-bottom: 24px;
    }

    .image {
      width: 588.65px;
      height: 474px;
      background: url("../../assets/images/video_call.png") no-repeat;
      background-size: contain;
      margin-left: 30px;
    }

    .chain {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .connector {
        width: 50px;
        height: 1px;
        background-color: #eef0f4;
        margin: 0 10px;
      }
      .question_icon,
      .onCall_icon,
      .checked_icon {
        width: 50px;
        height: 50px;
      }

      .question_icon {
        background: url("../../assets/icons/question.svg");
      }
      .onCall_icon {
        background: url("../../assets/icons/on_call.svg");
      }
      .checked_icon {
        background: url("../../assets/icons/checked.svg");
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 0px 50px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    margin: 0 auto;
    padding: 40px 20px;

    .title {
      line-height: 67.2px;
      text-align: center;
    }
    .description {
      margin-bottom: 56px;
      padding-inline: 20px;
      text-align: center;
      text-indent: 15px;
    }

    .content_description {
      text-align: center;
    }

    .content_block {
      flex-direction: column;
      margin-top: 0px;

      .chain {
        justify-content: center;
        margin-bottom: 40px;
      }

      .step {
        margin: 0 auto;
        margin-bottom: 24px;
      }

      .image {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 20px;
        background-position: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;

    .title {
      font-size: 26px;
      line-height: 36.4px;
      margin-bottom: 8px;
    }

    .description {
      line-height: 22.4px;
      margin-bottom: 56px;
    }
    .content_block {
      flex-direction: column;
      margin-top: 0px;

      .content_description {
        text-align: center;
      }

      .image {
        width: 100%;
        max-height: 264.11px;
        margin-bottom: 0;
        margin-left: 0;
        background-position: center;
      }
    }
    .content_block > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
  }
}
