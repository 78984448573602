.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 100px;
  background-color: var(--main-bgcolor);

  .title {
    width: 100%;
    max-width: 409px;
    font-size: var(--title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .description {
    width: 100%;
    max-width: 351px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }

  .image {
    width: 494px;
    height: 488px;
    background: url("../../assets/images/families.png") no-repeat;
    background-size: contain;
    margin-right: 30px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 60px 50px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    margin-top: 80px;
    padding: 40px 20px;
    flex-direction: column-reverse !important;

    .content {
      width: 100%;
      padding-inline: 20px;
    }

    .title {
      min-width: 100%;
      font-size: 36px;
      text-align: center;
    }

    .description {
      text-align: center;
      min-width: 100%;
    }

    .image {
      width: 100%;
      margin: 0 auto;
      background-position: center;
      margin-top: 56px;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;
    flex-direction: column-reverse;
    margin-bottom: 40px;

    .title {
      width: 100%;
      font-size: 26px;
      line-height: 36.4px;
      text-align: center;
      margin-bottom: 8px;
    }

    .description {
      text-align: center;
      margin-bottom: 56px;
    }

    .image {
      width: 100%;
      max-height: 324px;
      margin-right: 0;
    }
  }
}
