.container {
  display: none;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 42px;
    margin-right: 40px;
    margin-bottom: 40px;

    .divider {
      margin-inline: 5px;
    }

    .lang_select {
      border: none;
      font-size: var(--primary-font-size);
      line-height: 22.4px;
      font-weight: 500;
      background-color: #ffffff;
      color: #707070;
      text-align: center;
      cursor: pointer;
    }

    .lang_select_active {
      border: none;
      font-size: var(--primary-font-size);
      line-height: 22.4px;
      font-weight: 500;
      background-color: #ffffff;
      color: var(--accent-color);
      text-align: center;
      cursor: pointer;
    }
  }
}
