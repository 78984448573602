@import '../../../styles/variables.scss';

.stepper {
  &__label {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    &>svg {
      width: 20px;
      height: 20px;

      &>path {
        stroke-width: 2;
      }
    }
  }
}