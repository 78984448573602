.container {
  width: 100%;
  background-color: var(--main-bgcolor);
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 1;
  /* margin-top: 30px; */
}

.header {
  display: flex;
  position: relative;
  background-color: var(--main-bgcolor);
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  height: 70px;
  padding: 0 30px;

  .mobile_header_menu {
    display: none;
  }

  .only_logo_block {
    display: none;
  }

  .menu_icon {
    display: none;
  }
}

.header_logo {
  // width: 173px;
  height: 48px;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .mob_lang_select {
    display: none;
    width: 104px;
    position: absolute;
    right: 0;
    border: none;
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    background-color: #ffffff;
    color: #707070;
    margin-left: 52px;
    text-align: center;
    cursor: pointer;
  }
}

.header_interaction {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  margin-left: auto;

  .lang_select {
    width: 104px;
    border: none;
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    background-color: #ffffff;
    color: #707070;
    text-align: center;
    cursor: pointer;
  }

  .lang_select_active {
    width: 104px;
    border: none;
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    background-color: #ffffff;
    color: var(--accent-color);
    text-align: center;
    cursor: pointer;
  }

  .therapist_btn {
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    color: var(--accent-color);
  }

  .consult_btn {
    background-color: var(--accent-color);
    color: #ffffff;
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 11.5px 20px;
    margin-left: 42px;

    cursor: pointer;
  }
}

/* @media (max-width: 640px) {
  .header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    .mob_lang_select {
      display: block;
    }

    .header_interaction {
      margin: 0 auto;

      .lang_select {
        display: none;
      }

      .consult_btn {
        margin-left: 15px;
      }
    }
  }
} */

@media (min-width: 601px) and (max-width: 1024px) {
  .header {
    height: 70px;
    padding: 0 10px;
    align-items: center;

    .header_logo {
      justify-content: start;
    }
    .header_interaction {
      .therapist_btn {
        font-size: var(--primary-font-size);
        font-weight: 500;
        // width: 199px;
        text-align: center;
      }
      .consult_btn {
        width: 224px;
        margin-left: 16px;
      }
    }
  }

  .header_languageBlock {
    display: none;
  }
  .language_visible {
    display: block;
  }
}

@media (max-width: 600px) {
  .header {
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0px;

    .header_logo {
      justify-content: flex-start;
      padding-left: 16px;
    }

    .menu_bar {
      width: 24px;
      height: 16px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      .menu_line {
        width: 100%;
        height: 2px;
        background-color: #171717;
      }
    }

    .mobile_header_menu {
      position: absolute;

      left: 50%;
      transform: translate(-50%, 0);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 10;
      background-color: var(--secondary-bgcolor);

      .mobile_language_block {
        width: 150px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 10px 5px;

        .lang_select {
          width: 104px;
          border: none;
          font-size: var(--primary-font-size);
          line-height: 16.71px;
          background-color: inherit;
          color: #707070;
          text-align: center;
          cursor: pointer;
        }

        .lang_select_active {
          width: 104px;
          border: none;
          font-size: var(--primary-font-size);
          line-height: 16.71px;
          background-color: inherit;
          color: var(--accent-color);
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .mobile_therapist {
      margin-top: 20px;
      padding: 10px 5px;
    }

    .mobile_consultation {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: var(--accent-color);
      color: #ffffff;
      font-size: var(--primary-font-size);
      line-height: 16.71px;
      text-align: center;
      border: none;
      border-radius: 50px;
      padding: 10px 20px;

      cursor: pointer;
    }
  }

  .header_interaction {
    display: none;
  }
}
