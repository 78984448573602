.wrapper {
    width: 100%;
    max-width: var(--desktop-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.form_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 46px 150px;

    .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 32px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .form_item {
            margin-bottom: 20px;

            label {
                font-size: var(--secondary-font-size);
                line-height: 17px;
                color: #000000;
                margin: 0 6.5px 10px;
            }

            input[type='text'],
            input[type='tel'],
            input[type='email'] {
                width: 100%;
                max-width: 378px;
                border: 1px solid #D9D9D9;
                border-radius: 50px;
                padding: 11.5px 10px;
                outline: none;

            }

            select {
                width: 100%;
                max-width: 400px;
                border: 1px solid #D9D9D9;
                border-radius: 50px;
                padding: 11.5px 10px;
                outline: none;
                appearance: none;
            }

            .eng_select {
                background: url('../../assets/icons/arrow_down.svg') 98% 10px no-repeat;
            }

            .ar_select {
                background: url('../../assets/icons/arrow_down.svg') 3% 10px no-repeat;
            }

            .user_select {
                -moz-appearance: none !important;
                -webkit-appearance: none !important;
            }

            input[type='radio'] {
                -webkit-appearance: none;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                outline: none;
                border: 1px solid #D9D9D9;
                margin: 5px;
            }

            input[type='radio']:before {
                content: '';
                display: block;
                width: 60%;
                height: 60%;
                margin: 20% auto;
                border-radius: 50%;
            }

            input[type="radio"]:checked:before {
                background: var(--accent-color);
            }

            input[type="radio"]:checked {
                border-color: var(--accent-color);
            }

            input[type='checkbox'] {
                content: '';
                -webkit-appearance: none;
                background-color: transparent;
                border: 1px solid #D9D9D9;
                border-radius: 3px;
                padding: 10px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 5px;
            }

            input[type='checkbox']:checked:before {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 6px;
                width: 5px;
                height: 11px;
                border: solid var(--accent-color);
                border-radius: 1px;
                border-width: 0 2px 2px 0;
                transform: rotate(35deg);
            }

            input[type="checkbox"]:checked {
                border-color: var(--accent-color);
            }
        }

        .checkbox_label {
            letter-spacing: -0.02em;
            line-height: 21px !important;
            margin: 0 6.5px 4px !important;
        }

        input[type='submit'] {
            width: 100%;
            max-width: 400px;
            background: var(--accent-color);
            font-size: var(--secondary-font-size);
            line-height: 17px;
            color: #FFFFFF;
            border: none;
            border-radius: 50px;
            padding: 11.5px 0;
            cursor: pointer;
        }


        .inline_group {
            display: flex;

            .minus_btn {
                width: 40px;
                height: 40px;
                border: 1px solid #D9D9D9;
                border-bottom-left-radius: 50px;
                border-top-left-radius: 50px;
                position: relative;
                cursor: pointer;
            }

            .plus_btn {
                width: 40px;
                height: 40px;
                border: 1px solid #D9D9D9;
                border-bottom-right-radius: 50px;
                border-top-right-radius: 50px;
                position: relative;
                cursor: pointer;
            }

            .fa_minus {
                width: 20px;
                height: 20px;
                background: url('../../assets/icons/minus.svg') no-repeat;
                position: absolute;
                bottom: 1px;
                right: 5px;
            }

            .fa_plus {
                width: 20px;
                height: 20px;
                background: url('../../assets/icons/plus.svg') no-repeat;
                position: absolute;
                bottom: 7px;
                right: 7px;
            }
        }

        .age_input {
            width: 32px;
            height: 36px;
            text-align: center;
            border-bottom: 1px solid #D9D9D9;
            border-top: 1px solid #D9D9D9;
            border-left: none;
            border-right: none;
            outline: none;

        }

        .inline-group .age_input {
            text-align: right;
        }

        .age_input[type="number"]::-webkit-inner-spin-button,
        .age_input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

    }

    .flex_col {
        display: flex;
        flex-direction: column;
    }

    .flex_ai_center {
        display: flex;
        align-items: center;
    }

    .flex_ai_start {
        display: flex;
        align-items: flex-start;
    }
}

.image_wrapper {
    width: 50%;

    .slide_img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 1280px) {
    .form_wrapper {
        padding: 46px 60px;
    }
}


@media (max-width: 980px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    .form_wrapper {
        width: 100%;
    }

    input[type='submit'] {
        margin: 0 auto;
    }

    .image_wrapper {
        width: 100%;
    }
}