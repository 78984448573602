.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    .content_wrap {
        width: 100%;
        max-width: var(--desktop-width);
        height: calc(100vh - 300px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .text_wrap {
            margin: auto;
            text-align: center;

            h1 {
                font-size: var(--title-font-size);
                line-height: 60px;
                font-weight: 600;
            }

            h2 {
                font-size: 36px;
                line-height: 60px;
                font-weight: 600;
            }
        }

        .element {
            position: absolute;
            width: 100%;
            height: 100%;
            filter: blur(2px);
            background-repeat: no-repeat;

        }

        .curve_1 {
            top: 0px;
            left: 310px;
            background-image: url('../../assets/images/curve_1.svg');
            opacity: 0.3;
        }

        .curve_2 {
            top: 100px;
            left: -30px;
            background-image: url('../../assets/images/curve_2.svg');
            opacity: 0.2;
        }

        .curve_3 {
            top: 0px;
            left: 45%;
            background-image: url('../../assets/images/curve_3.svg');
            opacity: 0.5;
            filter: blur(10px);
        }

        .curve_4 {
            top: 65%;
            left: 63%;
            background-image: url('../../assets/images/curve_4.svg');
            opacity: 0.4;
            filter: blur(0px);
        }

        .curve_5 {
            top: 25%;
            left: 83%;
            background-image: url('../../assets/images/curve_5.svg');
            opacity: 0.5;
            filter: blur(10px);
        }
    }
}

@media (max-width: 780px) {
    .text_wrap {

        h1 {
            font-size: 36px !important;
        }

        h2 {
            font-size: 24px !important;
        }
    }
}