.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 84px 100px;
  background-color: var(--main-bgcolor);

  .title {
    font-size: var(--title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .description {
    width: 100%;
    max-width: 730px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--primary-color);
  }

  .content_block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 82px;

    > div > h1 {
      max-width: 392px;
    }

    > div > p {
      width: 100%;
      max-width: 447px;
      text-align: inherit;
    }

    .image {
      width: 545px;
      height: 447px;
      background: url("../../assets/images/calendar.png") no-repeat;
      background-size: contain;
      margin-left: 30px;
    }
  }

  .therapist_btn {
    background-color: var(--accent-color);
    color: #ffffff;
    font-size: var(--secondary-font-size);
    line-height: 16.71px;
    text-align: center;
    border: none;
    border-radius: 50px;
    padding: 11.5px 20px;
    margin-top: 20px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    padding: 40px 20px;

    .content_block {
      flex-direction: column !important;
      margin-top: 120px;

      .content_block_title {
        min-width: 100%;
        font-size: 36px;
      }

      .content_block_description {
        min-width: 100%;
        padding-inline: 0px;
        display: flex;
        justify-content: center;
      }

      .image {
        width: 100%;
        margin: 0 auto;
        background-position: center;
        margin-top: 56px;
      }
    }

    .content_block > div {
      width: 100%;
      text-align: center;
      padding-inline: 20px;
    }
    .description {
      max-width: 100%;
      padding-inline: 20px;
    }
    .therapist_btn {
      margin-top: 40px;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-top: 40px;
    padding-bottom: 0px;
    padding-inline: 16px;

    .content_block {
      margin-top: 120px;
      display: flex;
      flex-direction: column;
      text-align: center;

      .image {
        width: 328px;
        height: 447px;
        margin: 0;
        background-position: -170px -50px;
        background-size: cover;
        margin-top: 56px;
        border-radius: 16px;
      }
    }

    .content_block > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      text-align: center;
      margin: 0 auto;
      font-size: 26px;
      line-height: 36.4px;
      margin-bottom: 8px;
    }

    .description {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .therapist_btn {
      margin-top: 32px;
    }
  }
}
