@import "./reset.scss";
@import "./fonts.scss";
@import "./variables.scss";

html,
body {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: var(--main-bgcolor);
  color: var(--title-color);
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  // ::-webkit-scrollbar-track {
  //     background: #f1f1f1;
  //     border-radius: 10px;
  // }

  ::-webkit-scrollbar-thumb {
    background: #e8e6f2;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a9a7b6;
  }
}

// #root {
// }
