.wrapper {
    background: #FFFFFF;
    width: 50vw;
    max-height: 90vh;
    padding: 24px;
    position: absolute;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .header__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 36.4px;
    color: #262626;
  }

  .header__icon_block {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
  }

  .content {
    width: 100%;
    max-height: 90%;
    overflow: scroll;
    display: flex;
    justify-content: space-between;
  }

  .content__sections {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    height: 42px;
    margin-top: -20px;
  }

  .footer__cancel {
    margin-right: 16px;
    padding: 10px 20px 10px 20px;
    font-size: var(--primary-font-size);
    line-height: 22.4px;
    font-weight: 500;
    color: var(--accent-color);
    background-color: var(--main-bgcolor);
    border: none;
    cursor: pointer;
  }

  .footer__next {
    padding: 10px 16px;
    height: 42px;
    font-size: var(--primary-font-size);
    line-height: 22.4px;
    font-weight: 500;
    color: var(--main-bgcolor);
    background-color: var(--accent-color);
    border-radius: 50px;
    border: transparent;
    cursor: pointer;
  }

  @media (max-width: 1280px) {
    .wrapper {
      width: 60vw;
    }
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    width: 60vw;
    max-height: 584px;
  }
  
  .content {
    max-height: 410px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
  }
  
  .content__sections {
    margin-right: 24px;
  }
}

@media (max-width: 600px) {
  .wrapper {
    position: relative;
    width: 90vw;
    margin-top: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    
    .header {
      
      .header__title {
        width: 100%;
        font-size: 24px;
        line-height: 33.6px;
      }
    }
    
    .content {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
          display: none;
      }
    }

    .content__img_block {
      display: none;
    }
  }
}

@media (max-width: 420px) {
  .wrapper {
    .header {
      .header__title {
        max-width: 200px;
      }
    }
  }
}
