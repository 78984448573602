.wrapper {
    width: 100%;
    max-width: var(--desktop-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 100px 90px;

    .title {
        text-align: center;
        font-size: var(--title-font-size);
        line-height: 54.48px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    .lists_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 90px;

        .list {
            width: 100%;
            max-width: 424px;

            .list_title {
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                letter-spacing: -2%;
                margin-bottom: 30px;
            }

            .list_item {
                display: flex;
                gap: 9px;
                margin-bottom: 9px;
                font-size: var(--primary-font-size);
                line-height: 24px;
                letter-spacing: -2%;
                color: var(--primary-color);

                .item_icon {
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/icons/check_icon.svg') no-repeat;
                    background-size: contain;
                }
            }

            .divider {
                height: 1px;
                width: 100%;
                background-color: #EEF0F4;
                margin: 34px 0 30px;
            }

            .price {
                font-size: var(--title-font-size);
                line-height: 54.48px;
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 1024px) {
    .wrapper {
        padding: 60px 50px 90px;
    }
}

@media (max-width: 760px) {
    .wrapper {
        padding: 40px 20px 70px;
        
        .title {
            font-size: 36px !important;
        }

        .lists_wrapper {
            flex-direction: column;

            .price {
                text-align: center;
            }
        }
    }
}