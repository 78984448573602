.wrapper {
  width: 100%;
  max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 100px;
  background-color: var(--main-bgcolor);

  .image {
    width: 669px;
    height: 568px;
    // background: url('../../assets/images/personal_assistent.png') no-repeat;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 30px;
  }

  .title {
    width: 100%;
    max-width: 454px;
    font-size: var(--title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .description {
    width: 100%;
    max-width: 450px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }

  ul {
    margin-top: 24px;

    li {
      display: flex;
      justify-content: flex-start;
      gap: 11px;

      p {
        font-size: var(--secondary-font-size);
        line-height: 21px;
        letter-spacing: -0.02em;
        color: var(--primary-color);
        margin-bottom: 11px;
        flex: 1;
      }
    }
  }

  .check_icon {
    width: 24px;
    height: 25px;
    max-width: 25px;
    flex: 1;
    background: url("../../assets/icons/check_icon.svg") no-repeat;
    background-size: contain;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    padding: 40px 40px;
    flex-direction: column-reverse !important;

    .wrapper_content {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .list_items {
        width: 100%;
        padding-inline: 16px;
        height: 94px;
        margin-bottom: 56px;
      }

      .title {
        text-align: center;
      }

      .description {
        min-width: 600px;
        text-align: center;
        text-indent: 40px;
        line-height: 22.4px;
      }
    }

    .image {
      width: 100%;
      height: 530px;
      margin-left: 40px;
      margin-right: 42px;
      background-position: center;
      margin-bottom: 40px;
    }

    // .check_icon {
    //     max-width: 25px;
    //     flex: 1;
    // }

    // ul li p {
    //     flex: 1;
    // }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;
    display: flex;
    flex-direction: column-reverse;

    .list_items {
      margin-bottom: 56px;
    }

    .title {
      text-align: center;
      font-size: 36px;
      line-height: 50.4px;
      margin-bottom: 8px;
    }

    .image {
      width: 100%;
      height: 270px;
      margin-right: 0;
      background-position: center;
      margin-bottom: 20px;
    }
  }
}
