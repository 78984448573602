.wrapper {
  width: 100%;
  // max-width: var(--desktop-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-bgcolor);

  .title {
    width: 100%;
    max-width: 938px;
    font-size: var(--extra-title-font-size);
    line-height: 54.48px;
    font-weight: 600;
    text-align: center;
    margin: 80px 0 24px;
    color: var(--black);
  }

  .description {
    width: 100%;
    max-width: 782px;
    font-size: var(--primary-font-size);
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--black);
  }

  .experts {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 22px;
    padding: 0 10px;
    margin-top: 59px;
    border-bottom: 1px solid rgb(231 231 231);

    .card_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 212px;
        height: 212px;
        border-radius: 50%;
      }

      .card_descr {
        padding: 34px 0px;

        .name {
          font-size: var(--subtitle-font-size);
          line-height: 30px;
          font-weight: 600;
          letter-spacing: -0.02em;
        }

        .speciality {
          font-size: var(--primary-font-size);
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--primary-color);
          margin-bottom: 19px;
        }

        .country {
          display: flex;
          align-items: center;

          .UK_flag {
            width: 23px;
            height: 16px;
            background: url("../../assets/icons/UK_flag.svg") no-repeat;
            margin-right: 0;
          }

          .US_flag {
            width: 23px;
            height: 16px;
            background: url("../../assets/icons/US_flag.svg") no-repeat;
            margin-right: 0;
          }

          .EG_flag {
            width: 23px;
            height: 15.33px;
            background: url("../../assets/icons/EG_flag.svg") no-repeat;
            margin-right: 0;
          }
        }

        .languages {
          display: flex;
          align-items: center;
          margin-top: 7px;
        }

        .skills {
          display: flex;
          align-items: center;
          margin-top: 7px;

          .ar_skills {
            display: flex;
          }
        }

        .experience {
          display: flex;
          align-items: center;
          margin-top: 25px;

          span:first-of-type {
            font-size: 38px;
            line-height: 40px;
            font-weight: 500;
            letter-spacing: -0.02em;
            color: var(--accent-color);
          }

          span:last-of-type {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: -0.02em;
            color: var(--primary-color);
          }
        }

        .square {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--accent-color);
          border-radius: 50%;
          margin-right: 9px;

          .lang {
            width: 17.58px;
            height: 17.09px;
            background: url("../../assets/icons/language.svg") no-repeat;
            margin-right: 0;
          }

          .certificate {
            width: 12.94px;
            height: 18.12px;
            background: url("../../assets/icons/certificate.svg") no-repeat;
            margin-right: 0;
          }
        }

        span {
          font-size: var(--primary-font-size);
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--primary-color);
          margin-right: 9px;
        }
      }
    }
  }
  .wrapper_slider {
    display: none;
  }
}

/* @media (max-width: 1024px) {
  .experts {
    padding: 0 50px !important;
  }
}

@media (max-width: 895px) {
  .experts {
    justify-content: flex-start !important;
  }
} */

@media (min-width: 601px) and (max-width: 1024px) {
  .wrapper {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 160px;

    .title {
      max-width: 100%;
      padding-inline: 40px;
      font-size: 26px;

      margin-bottom: 8px;
      line-height: 36.4px;
      color: var(--black);
    }

    .description {
      width: 100%;
      margin: 0 auto;
      padding-inline: 40px;
      line-height: 22.4px;
      margin-bottom: 56px;
      color: var(--black);
    }
    .experts {
      display: none;
    }
    .wrapper_slider {
      display: block;
      max-width: 100%;
      max-height: 675px;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding-inline: 16px;
    .title {
      font-size: 24px;
      text-align: left;
      line-height: 33.6px;
      margin-bottom: 8px;
    }
    .description {
      width: 100%;
      line-height: 22.4px;
      margin-bottom: 56px;
    }
    .experts {
      display: none;
    }
    .wrapper_slider {
      display: block;
      width: 100%;
      max-height: 597px;
    }
  }
}
